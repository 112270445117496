.values {
	.media-object {
		display: block;
		max-height: 120px;
	}
	.media-heading {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	p {
		font-size: 14px;
	}
}
@media (max-width: 425px) {
	.values {
		.media {
			margin-bottom: 15px;
		}
	}
}