.page-text {
	.text {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 50px;
		-moz-column-gap: 50px;
		column-gap: 50px;
	}
	.video {
		margin-top: 60px;
	}
}

@media (max-width: 425px) {
	.page-text {
		.text {
			-webkit-column-count: 1;
			-moz-column-count: 1;
			column-count: 1;
			padding: 0 30px;
		}
	}
	.video {
		margin-top: 40px;
	}
}