.landing-page {
	padding: 80px 0 60px 0;
	-webkit-box-shadow: inset 0px 21px 27px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: inset 0px 21px 27px 0px rgba(0,0,0,0.1);
	box-shadow: inset 0px 21px 27px 0px rgba(0,0,0,0.1);
}

@media (min-width: 769px) {
	.landing-page {
		.text {
			padding-left: 30px;
		}
	}
}

@media (max-width: 768px) {
	.landing-page {
		.thumbnail-img {
			padding-bottom: 20px;
			img {
				width: 100%;
			}
		}
	}
}