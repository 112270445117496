.navbar {
	.container {
		position: relative;
	}
}
.contact {
	position: absolute;
	right: 15px;
	top: 15px;
	span {
		font-family: 'Roboto Slab', serif;
		font-size: 15px;
		display: block;
		line-height: 1;
	}
	a {
		color: @yellow;
		font-size: 28px;
		font-weight: bold;
		line-height: 1;
	}
}
.navbar-nav {
	>li {
		>a {
			color: #000;
			padding-bottom: 25px;
			border-bottom: 5px solid transparent;
		}
		&.active {
			a {
				border-bottom: 5px solid @yellow;
			}
		}
		&:hover {
			a {
				border-bottom: 5px solid @yellow;
			}
		}
	}
	ul {
		&.dropdown-menu {
			>li {
				>a {
					font-weight: 300;
					border-bottom: 5px solid transparent;
					&:hover {
						background-color: #fff;
						border-bottom: 5px solid @yellow;
					}
					&:focus {
						background-color: #fff;
						border-bottom: 5px solid @yellow;
					}
				}
			}
		}
	}
}
.nav {
	>li {
		>a {
			&:hover {
				text-decoration: none;
				background-color: #fff;
			}
			&:focus {
				text-decoration: none;
				background-color: #fff;
			}
		}
	}
	.open {
		>a {
			background-color: #fff;
			border: none;
			&:hover {
				background-color: #fff;
				border-bottom: 5px solid @yellow;
			}
			&:focus {
				background-color: #fff;
				border-bottom: 5px solid @yellow;
			}
		}
	}
}
.navbar {
	margin-bottom: 0;
}
.navbar-brand {
	height: auto;
	padding: 30px 15px;

	> img {
		max-height: 103px;
	}
}
.navbar-toggle {
	// position: absolute;
	// right: 0;
	// top: 50%;
	// transform: translate(-50%,-50%);
	margin-top: 72px;
	background-color: @yellow;
	.icon-bar {
		display: block;
		background-color: #000;
	}
}
@media (min-width: 768px) {
	ul.nav {
		li.dropdown {
			&:hover {
				ul.dropdown-menu {
					display: block;
				}
			}
		}
	}
	.navbar-right {
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
}
// Collapse breakpoint
// @media (max-width: 768px) {
// 	.navbar-header {
// 		float: none;
// 	}
// 	.navbar-left {
// 		float: none !important;
// 	}
// 	.navbar-right {
// 		float: none !important;
// 	}
// 	.navbar-toggle {
// 		display: block;
// 	}
// 	.navbar-collapse {
// 		border-top: 1px solid transparent;
// 		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
// 	}
// 	.navbar-fixed-top {
// 		top: 0;
// 		border-width: 0 0 1px;
// 	}
// 	.navbar-collapse.collapse {
// 		display: none !important;
// 	}
// 	.navbar-nav {
// 		float: none !important;
// 		margin-top: 7.5px;
// 		>li {
// 			float: none;
// 			>a {
// 				padding-top: 10px;
// 				padding-bottom: 10px;
// 			}
// 		}
// 	}
// 	.collapse.in {
// 		display: block !important;
// 	}
// }

@media (min-width: 426px) {
	.navbar-collapse {
		.navbar-right {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
	.contact {
		top: 30px;
	}
}

@media (max-width: 425px) {
	.navbar-nav {
		>li {
			>a {
				padding-bottom: 15px;
			}
			&.active {
				a {
					border-bottom: none;
					font-weight: 400;
				}
			}
		}
	}
}