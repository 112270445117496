.blog-post {
	margin-top: 0;
	.blog-img {
		img {
			width: 100%;
		}
	}
	.post {
		padding: 0 70px;
		.text {
			border-left: 1px solid #575757;
			padding: 100px;
			padding-bottom: 50px;
			position: relative;

			.main {
				font-size: 50px;
				margin-bottom: 40px;
			}
			p {
				margin-bottom: 20px;
			}
			h4 {
				margin-bottom: 20px;
			}
			img {
				margin-bottom: 20px;
				width: 100%;
			}

			.date {
				top: -36px;
				left: -36px;
			}

			.back {
				display: inline-block;
				margin-top: 50px;
				font-size: 20px;
				color: #000;

				i {
					color: @yellow;
					padding-right: 10px;
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.blog-post {
		.post {
			.text {
				padding: 60px;
				padding-bottom: 30px;

				.main {
					font-size: 40px;
				}
			}
		}
	}
}
@media (max-width: 425px) {
	.blog-post {
		.post {
			padding: 0 30px;
			.text {
				padding: 15px;
				padding-bottom: 15px;

				.main {
					font-size: 30px;
					margin-top: 30px;
				}
			}
		}
	}
}