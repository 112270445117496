.latest-news {
	background-color: @lightgray;
	padding: 70px 0 40px 0;
	margin-top: 0;
	.row {
		margin-bottom: 30px;
		width: 100%;
	}
	.all-news {
		.main {
			font-size: 40px;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		.sub {
			font-size: 26px;
		}
		.inner {
			background-color: @yellow;
			padding: 56px;
			height: 100%;
			-webkit-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
			-moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
			box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		}
	}
	.news-post {
		.inner {
			background-color: #fff;
			padding: 0;
			/*height: 100%;*/
			-webkit-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
			-moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
			box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
			overflow: hidden;
		}

		.text {
			background-color: #fff;
			color: #000;
			padding: 56px;
			position: relative;
			height: 100%;

			&.hz {
				height: auto;
			}

			h4 {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 20px;
			}
			p {
				font-size: 14px;
			}
		}

		.image {
			padding: 0;
			overflow: hidden;
			position: relative;
			min-height: 270px;

			img {
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
				min-width: 100%;
				width: auto;
				min-height: 100%;
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				-o-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}

			/*&.vt {
				height: 100%;

				img {
					height: auto;
				}
			}*/
		}
	}
	&.blog {
		background-color: #fff;
		margin-top: 0;
		border-top: 1px solid #000;
	}
	&.blog-post {
		background-color: #fff;
		margin-top: 0;

		.heading {
			.main {
				font-size: 40px;
			}
		}
	}
}
@media (max-width: 768px) {
	.latest-news {
		.row {
			margin: 0;
		}
		.all-news {
			display: block;
			margin-bottom: 30px;
		}
		.news-post {
			display: block;
			margin-bottom: 30px;

			.image {
				img {
					height: auto;
				}
			}
		}
	}
}
@media (min-width: 426px) and (max-width: 768px) {
	.latest-news {
		.news-post {
			.inner {
				height: 100%;
			}
			.image {
				&.vt {
					height: 100%;
					img {
						height: auto;
					}
				}
			}
		}
	}
}
@media (min-width: 769px) {
	.latest-news {
		.news-post {
			display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
			display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
			display: -ms-flexbox;      /* TWEENER - IE 10 */
			display: -webkit-flex;     /* NEW - Chrome */
			display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
			.flex-sm {
				display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
				display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
				display: -ms-flexbox;      /* TWEENER - IE 10 */
				display: -webkit-flex;     /* NEW - Chrome */
				display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
			}
		}
	}
}