html {
	overflow-x: hidden;
	max-width: 100%;
}

body {
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden;
	max-width: 100%;
}

h1 {
	font-family: 'Roboto Slab', serif;
}

h2 {
	font-family: 'Roboto Slab', serif;
}

h3 {
	font-family: 'Roboto Slab', serif;
}

h4 {
	font-family: 'Roboto Slab', serif;
}

h5 {
	font-family: 'Roboto Slab', serif;
}

h6 {
	font-family: 'Roboto Slab', serif;
}

p {
	font-size: 16px;
	font-weight: 300;
}

a {
	font-size: 16px;
	font-weight: 300;

	&:hover {
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	&:visited {
		text-decoration: none;
	}
}

ul {
	padding-left: 0;

	li {
		list-style: none;

		a {
			&:hover {
				text-decoration: none;
			}

			&:focus {
				text-decoration: none;
			}

			&:active {
				text-decoration: none;
			}

			&:visited {
				text-decoration: none;
			}
		}
	}
}

section {
	margin-top: 60px;
}

.grid-section {
	margin-top: 60px;

	h1 {
		font-size: 40px;
		margin-bottom: 50px;
	}

	.main {
		font-size: 40px;
		margin-bottom: 50px;
	}

	img {
		width: 100%;
		margin-bottom: 40px;
		-webkit-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		-moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
	}

	.video {
		margin-bottom: 20px;
	}
}

.umb-grid {
	margin-bottom: 60px;
}

h1 {
	font-family: 'Roboto Slab', serif;
	font-weight: bold;
	display: block;
	line-height: 1;
	color: #000;
	margin-top: 0;
}

.main {
	font-family: 'Roboto Slab', serif;
	font-weight: bold;
	display: block;
	line-height: 1;
	color: #000;
}

.sub {
	display: block;
	line-height: 1;
	color: #000;
}

.heading {
	text-align: center;
	width: 70%;
	margin: 0 auto;
	padding-bottom: 60px;

	.main {
		font-size: 50px;
		margin-bottom: 20px;
	}

	.sub {
		font-size: 26px;
		line-height: 1.2;
	}
}

.map {
	margin-bottom: -5px;

	iframe {
		width: 100%;
		height: 362px;
	}
}

.default-page {
	margin-top: 0;
	padding: 70px 0 0 0;
	border-top: 1px solid #000;

	.text {
		text-align: left;
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 50px;
		-moz-column-gap: 50px;
		column-gap: 50px;
	}
}

.video {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	z-index: 3;
	overflow: hidden;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.media {
	margin-top: 0;
}

.media-body {
	vertical-align: middle;
}

.body {
	height: 100%;
	position: absolute;
	z-index: 20;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.table {
		height: 100%;
		display: table;
		margin-bottom: 0;

		.cell {
			height: 100%;
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			.hero-main {
				font-size: 60px;
				font-weight: 300;
				color: @white;
				padding-bottom: 20px;
				display: block;
			}

			.hero-sub {
				font-size: 20px;
				font-weight: 300;
				color: @mediumgray;
				display: block;
				width: 40vw;
				margin: 0 auto;
				color: white;
			}
		}
	}
}

.dropdown {
	position: relative;

	.select {
		text-align: center;
		border: 1px solid #9a9a9a;
		border-radius: 3px;
		width: 42px;
		height: 30px;
		font-size: 14px;
		/* Remove default dropdown */
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		padding-left: 10px;

		&::-ms-expand {
			display: none;
		}
	}

	.fa-angle-down {
		position: absolute;
		font-size: 16px;
		color: #9a9a9a;
		right: 27px;
		bottom: 7px;
		/* Click "through" the icon to enable dropdown */
		pointer-events: none;
	}
}

.slider {
	padding-bottom: 60px;
}

.slide-animation {
	transition: -webkit-transform .4s ease-in;
	transition: transform .4s ease-in;
	transition: transform .4s ease-in,-webkit-transform .4s ease-in;
}

.present {
	h1 {
		font-size: 40px;
		margin-bottom: 50px;
	}

	.main {
		font-size: 40px;
		margin-bottom: 50px;
	}

	img {
		width: 100%;
		margin-bottom: 40px;
		-webkit-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		-moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
	}
}

.date {
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 72px;
	height: 72px;
	background-color: @yellow;
	border-radius: 100%;
	padding: 20px 14px;
	line-height: 1;
	z-index: 2;

	span {
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
		line-height: 1;

		&.year {
			font-size: 12px;
			font-weight: normal;
			line-height: 1;
		}
	}

	&.tl {
		top: 30px;
		left: -36px;
	}

	&.bl {
		top: auto;
		bottom: -36px;
		left: 40px;
	}

	&.br {
		top: auto;
		left: auto;
		bottom: 30px;
		right: -36px;
	}
}

.image {
	img {
		width: 100%;
	}
}

.eq-height > [class*='col-'] {
	background-clip: content-box;
	flex-direction: column; // FF 47-
	-webkit-flex-direction: row; // iOS safari
	-ms-flex-direction: column; // IE11
}

@media (min-width: 769px) {

	.row-flex {
		display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
		display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
		display: -ms-flexbox; /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Chrome */
		display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
	}
}

// Telephone links not clickable on desktop
@media (min-width: 769px) {
	a[href^=tel] {
		display: inline-block;
		pointer-events: none;
	}

	.dt {
		display: table;

		.dtc {
			float: none;
			display: table-cell;
			vertical-align: top;
			height: 100%;
		}
	}

	.thumbnail-img {
		overflow: hidden;
		position: relative;

		img {
			height: 100%;
			width: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}

@media (min-width: 426px) and (max-width: 768px) {
	.dt-sm {
		display: table;

		.dtc-sm {
			float: none;
			display: table-cell;
			vertical-align: top;
			height: 100%;
		}
	}

	.flex-sm {
		display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
		display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
		display: -ms-flexbox; /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Chrome */
		display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
	}

	.latest-news {
		.news-post {
			.image.vt {
				height: auto !important;
			}
		}
	}

	.eq-h-sm > [class*='col-'] {
		background-clip: content-box;
		flex-direction: column; // FF 47-
		-webkit-flex-direction: row; // iOS safari
		-ms-flex-direction: column; // IE11
	}

	.eq-h-sm {
		display: flex;
		flex-wrap: wrap;
	}

	.eq-h-sm > [class*='col-'] {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		-webkit-flex-direction: row;
		-ms-flex-direction: column;
	}

	.eq-h-sm:before {
		content: normal;
	}

	.eq-h-sm:after {
		content: normal;
	}
}

@media (max-width: 768px) {
	.present {
		img {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.grid-section {
		img {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.heading {
		width: 80%;

		h1 {
			font-size: 40px;
		}

		.main {
			font-size: 40px;
		}

		.sub {
			font-size: 24px;
		}
	}
}

@media (max-width: 425px) {
	section {
		margin-top: 40px;
	}

	.grid-section {
		margin-top: 40px;

		.video {
			margin-top: 20px;
			margin-bottom: 0;
		}
	}

	.umb-grid {
		margin-bottom: 40px;
	}

	.slider {
		padding-bottom: 40px;
	}

	.default-page {
		padding: 40px 0 0 0;

		.text {
			-webkit-column-count: 1;
			-moz-column-count: 1;
			column-count: 1;
			padding: 0 30px;
		}
	}

	.date {
		&.tl {
			top: -36px;
			left: 20px;
		}

		&.br {
			bottom: -36px;
			right: 20px;
		}
	}

	.present {
		.main {
			font-size: 30px;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		h1 {
			font-size: 30px;
		}
	}

	.grid-section {
		.main {
			font-size: 30px;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		h1 {
			font-size: 30px;
		}
	}

	.heading {
		width: 100%;

		.main {
			font-size: 30px;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		h1 {
			font-size: 30px;
		}
	}
}
