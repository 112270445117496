.service {
	display: block;
	padding: 0;
	padding-bottom: 40px;
	position: relative;
	img {
		width: 100%;
		box-shadow: 11px 11px 24px 0px rgba(0,0,0,0.21);
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
	.text {
		padding: 30px;
		background-color: @yellow;
		opacity: 0.9;
		position: absolute;
		width: 70%;
		transition-duration: 0.4s;

		&.hz {
			width: 55%;
		}

		&.bl {
			left: 20px;
			bottom: 20px;
		}
		&.br {
			right: 20px;
			bottom: 20px;
		}
		&.tr {
			right: 20px;
			top: -20px;
		}

		.main {
			font-size: 30px;
			margin-bottom: 20px;
			text-transform: uppercase;
			color: #000;
		}
		p {
			color: #000;
		}
	}
	&:hover {
		.text {
			opacity: 1;
		}
	}
}
.thumbnail-zoom {
	margin-bottom: 30px;
	.inner {
		position: relative;
		-webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		overflow: hidden;
		border-top: 8px solid @yellow;
		border-bottom: 3px solid @yellow;
	}
	.thumbnail-img {
		min-height: 287px;
		height: 287px;
		max-height: 287px;
		width: 100%;
		display: block;
		overflow: hidden;
		position: relative;
		-moz-transition: all 0.5s;
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
		img {
			width: auto;
			height: auto;
			min-height: 100%;
			min-width: 100%;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		&:hover {
			-moz-transform: scale(1.1);
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
	.thumbnail-text {
		height: 245px;
		padding: 45px;
		position: relative;
		background-color: #f9f9f9;
		.title {
			font-size: 24px;
			font-family: 'Roboto Slab';
			font-weight: bold;
			color: #000;
			line-height: 1;
			margin-bottom: 10px;
			text-transform: uppercase;
			text-decoration: underline;
		}
		p {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			color: #000;
			font-weight: 400;
			&.read-more {
				font-family: 'Roboto Slab';
				position: absolute;
				bottom: 45px;
				left: 45px;
				font-weight: 600;
				text-transform: uppercase;
			}
		}
		i {
			margin-left: 11px;
		}
	}
}
@media (max-width: 1024px) {
	.thumbnail-zoom {
		.thumbnail-text {
			height: 265px;
		}
	}
}
@media (max-width: 768px) {
	.service {
		.text {
			width: 100%;
			&.hz {
				width: 68%;
			}
			.main {
				font-size: 20px;
			}
			p {
				font-size: 14px;
			}
		}
	}
	.thumbnail-zoom {
		.thumbnail-text {
			height: 330px;
		}
	}
}
@media (max-width: 425px) {
	.service {
		padding-bottom: 0;
		margin-bottom: 15px;
		overflow: hidden;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
			height: 100%;
			width: auto;
		}
		.text {
			position: relative;
			width: 100% !important;
			top: 0 !important;
			left: 0 !important;
			bottom: 0 !important;
		}
	}
	.thumbnail-zoom {
		.thumbnail-text {
			height: auto;
			padding: 35px;
			.title {
				font-size: 21px;
				word-break: break-word;
			}
			p.read-more {
				position: relative;
				display: block;
				bottom: initial;
				left: initial;
			}
		}
	}
}
@media (max-width: 320px) {
	.thumbnail-zoom {
		.thumbnail-text {
			padding: 30px;
			.title {
				font-size: 20px;
			}
		}
	}
}