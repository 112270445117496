.employees {
	margin-top: 0;
    padding: 70px 0 40px 0;
    border-top: 1px solid #000;
}
.employee {
    padding-bottom: 30px;

    .inner {
        -webkit-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
        box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
        border-bottom: 5px solid @yellow;
        overflow: hidden;
        height: 100%;

        .employee-img {
            width: 100%;
            height: 213px;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                min-width: 100%;
                width: auto;
                min-height: 100%;
                height: auto;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
        }

        .text {
            padding: 40px;
            text-align: center;
            background-color: #fff;

            p {
                &.name {
                    font-family: 'Roboto Slab', serif;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 0;
                    line-height: 1;
                    color: #000;
                }

                &.position {
                    font-size: 17px;
                    margin-bottom: 30px;
                }
            }

            a {
                display: block;
                color: #575757;
                font-size: 14px;
                margin-bottom: 10px;

                &.email {
                    text-decoration: underline;
                    margin-bottom: 0;
                }
            }
        }
    }
}