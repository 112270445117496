.about-sections {
	background-color: @lightgray;
	padding: 60px 0;
	.inner {
		-webkit-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		-moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		border-bottom: 5px solid @yellow;
		overflow: hidden;
		height: 100%;
		background-color: #fff;
		.image {
			width: 100%;
			height: 213px;
			position: relative;
			overflow: hidden;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
				width: 100%;
				width: auto;
				min-height: 100%;
				height: auto;
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
			}
		}
		.text {
			padding: 40px 80px;
			text-align: center;
			background-color: #fff;
			color: #000;

			h4 {
				font-size: 20px;
				text-transform: uppercase;
				font-weight: bold;
			}
		}
	}
}

@media (max-width: 1024px) {
	.about-sections {
		.inner {
			.text {
				padding: 20px 40px;
			}
		}
	}
}

@media (max-width: 768px) {
	.about-sections {
		.inner {
			.text {
				padding: 20px;
			}
		}
	}
}

@media (max-width: 425px) {
	.about-sections {
		.section {
			display: block;
			padding-bottom: 30px;
		}
	}
}