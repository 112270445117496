.btn {
	font-family: 'Roboto Slab', serif;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
	text-transform: uppercase;
	border-radius: 0;
	padding: 21px 86px 21px 28px;
	position: relative;
	border: none;
	line-height: 1;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;

	i {
		position: absolute;
		top: 0;
		right: 0;
		padding: 21px;
	}

	&.bg {
		background-color: #000;
		-webkit-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		-moz-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
		box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);

		i {
			color: #fff;
			background-color: @darkgray;
		}
		&:hover {
			background-color: @darkgray;
		}
	}

	&.by {
		padding: 22px 86px 22px 28px;
		background-color: transparent;
		border: 1px solid @yellow;

		i {
			color: #000;
			border: 1px solid @yellow;
			background-color: @yellow;
		}
		&:hover {
			background-color: @yellow;
			color: #000;
		}
	}
	&:hover {
		color: #fff;
	}
}