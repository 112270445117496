.testimonials {
    background-color: @lightgray;
    padding: 130px 0 100px 0;

    .testimonial {
        position: relative;

        .logo {
            width: 120px;
            height: 120px;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -67%);
            -ms-transform: translate(-50%, -67%);
            -o-transform: translate(-50%, -67%);
            transform: translate(-50%, -67%);

            img {
                width: 100%;
                border-radius: 100%;
                border: 10px solid #f8f8f8;
                background-color: #fff;
                -webkit-box-shadow: 0px 18px 21px 0px rgba(0,0,0,0.09);
                -moz-box-shadow: 0px 18px 21px 0px rgba(0,0,0,0.09);
                box-shadow: 0px 18px 21px 0px rgba(0,0,0,0.09);
            }
        }

        .inner {
            padding: 70px 40px;
            background-color: #fff;
            -webkit-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
            -moz-box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
            box-shadow: 11px 11px 24px 0px rgba(0, 0, 0, 0.21);
            height: 100%;

            p {
                font-size: 14px;
            }

            .quote-title {
                font-family: 'Roboto Slab', serif;
                font-size: 16px;
                font-weight: bold;
            }

            .quote {
                font-style: italic;
                margin-bottom: 30px;
            }

            .name {
                margin-bottom: 0;
            }

            .company {
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 425px) {
	.testimonials {
		.testimonial {
			padding-bottom: 30px;
		}
	}
}