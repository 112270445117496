@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?r0iywb');
  src:  url('fonts/icomoon.eot?r0iywb#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?r0iywb') format('truetype'),
  url('fonts/icomoon.woff?r0iywb') format('woff'),
  url('fonts/icomoon.svg?r0iywb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
  content: "\e902";
}
.icon-plus:before {
  content: "\e903";
}
.icon-phone:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-checkmark:before {
  content: "\e906";
}
.icon-cancel:before {
  content: "\e907";
}
.icon-chevron-left:before {
  content: "\e908";
}
.icon-chevron-right:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e910";
}
.slick-next:before {
  content: "\e901";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.slick-prev:before {
  content: "\e900";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.icon-bag:before {
  content: "\e90a";
}
.icon-help:before {
  content: "\e90b";
}
.icon-info-with-circle:before {
  content: "\e90c";
}
.icon-log-out:before {
  content: "\e90d";
}
.icon-shopping-bag:before {
  content: "\e90e";
  color: #fff;
}
.icon-list:before {
  content: "\e90f";
}

/*Ligature Symbols*/
@font-face {
    font-family: 'LigatureSymbols';
    src: url('fonts/LigatureSymbols-2.11.eot');
    src: url('fonts/LigatureSymbols-2.11.eot?#iefix') format('embedded-opentype'),
         url('fonts/LigatureSymbols-2.11.woff') format('woff'),
         url('fonts/LigatureSymbols-2.11.ttf') format('truetype'),
         url('fonts/LigatureSymbols-2.11.svg#LigatureSymbols') format('svg');
    src: url('fonts/LigatureSymbols-2.11.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.lsf, .lsf-icon:before {
  font-family: 'LigatureSymbols';
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.lsf-icon:before {
  content:attr(title);
  margin-right:0.3em;
  font-size:130%;
}

.lsf-icon.upload:before {
  content: '\E136';
}