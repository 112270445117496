.panel-group {
	.panel {
		border: 1px solid #575757;
		border-radius: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		margin-bottom: 15px;
		&+.panel {
			margin-top: 0;
		}
		.panel-heading {
			padding: 0;
			background-color: #fff;
			cursor: pointer;
			+.panel-collapse {
				-webkit-transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;
				>.panel-body {
					border-top: none;
				}
			}
			.panel-title {
				position: relative;
				>a {
					padding: 25px 50px;
					display: block;
					text-decoration: none;
					color: #000;
					font-size: 20px;
					font-weight: bold;
				}
				i {
					display: inline-block;
					-webkit-transition-duration: 0.4s;
					transition-duration: 0.4s;
					padding: 28px 10px;
					position: absolute;
					top: 0;
					left: 8px;
					pointer-events: none;
					&:before {
						font-size: 16px;
					}
				}
			}
		}
		.panel-body {
			padding: 20px 50px 40px 50px;
			font-size: 16px;
			overflow: hidden;
			h4 {
				font-family: 'Open Sans', sans-serif;
				font-size: 16px;
			}
			label {
				font-family: 'Open Sans', sans-serif;
				font-size: 16px;
				font-weight: normal;
				&.lsf-icon {
					font-size: 20px;
				}
			}
			input#desired-hours {
				margin-left: 5px;
				border: 1px solid @darkgray;
				width: 47px;
				display: inline-block;
				padding: 0 5px;
			}
		}
	}
}
@media (max-width: 425px) {
	.panel-group {
		.panel {
			.panel-body {
				padding: 40px 30px;
			}
		}
	}
}