.clients {
	padding: 40px 0 25px 0;
	text-align: center;
	ul {
		li {
			display: inline-block;
			padding: 0 10px;
			padding-bottom: 15px;
		}
	}
	.slick-track {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		.slick-slide {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			height: auto;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
		}
	}
}