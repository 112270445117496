.hero {
	padding: 0;
	background: #000;
	overflow: hidden;
	position: relative;
	z-index: 2;
	cursor: default;

	.container {
		position: relative;
	}

	.image {
		width: auto;
		height: 100%;

		.image-inner {
			position: relative;
			height: 100%;

			img {
				min-width: 100%;
				width: auto;
				min-height: 100%;
				height: auto;
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
			}
		}
	}

	.curtain-container {
		width: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		.curtain-inner {
			.curtain {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 60%;
				height: 100%;
			}

			.text {
				position: absolute;
				top: 22%;
				left: 8%;
				width: 35%;
				padding: 50px;

				.main {
					font-size: 50px;
					line-height: 1;
					margin-bottom: 15px;
				}

				.sub {
					font-size: 20px;
					margin-bottom: 35px;
				}
			}
		}
	}

	.hero-text {
		.inner {
			width: 31%;
			position: absolute;
			top: 67px;
			left: 15%;
			background-color: @yellow;
			padding: 60px;
			text-transform: uppercase;
			z-index: 1;

			.main {
				font-size: 42px;
				text-transform: uppercase;
				margin-bottom: 20px;
				-webkit-hyphens: auto;
				-ms-hyphens: auto;
				hyphens: auto;
			}

			.sub {
				font-size: 20px;
				text-transform: none;
			}

			&:after {
				content: '';
				width: 75px;
				height: 45px;
				background-color: @yellow;
				opacity: 0.8;
				position: absolute;
				left: -23px;
				top: -13px;
				z-index: -1;
			}

			&:before {
				content: '';
				width: 100px;
				height: 100px;
				background-color: @yellow;
				opacity: 0.8;
				position: absolute;
				left: -12px;
				top: -22px;
				z-index: -1;
			}
		}
	}

	&.dark {
		height: 100vh;
		height: calc(~'100vh - 161px');

		.image {
			opacity: 0.2;

			.image-inner {
				img {
					-webkit-filter: none;
					filter: none;
				}
			}
		}

		.body {
			.table {
				width: 60%;
				margin: 0 auto;

				.cell {
					.hero-main {
						font-size: 50px;
						font-weight: bold;
						line-height: 1.2;
						margin-bottom: 18px;
					}

					.hero-sub {
						font-size: 26px;
						width: 100%;
					}

					a {
						margin-top: 50px;
						background-color: transparent;

						&:hover {
							color: @yellow;
						}

						&:focus {
							color: @yellow;
						}

						&:visited {
							color: @yellow;
						}

						&:active {
							color: @yellow;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	.hero {
		.curtain-container {
			.curtain-inner {
				.text {
					left: 5%;

					.main {
						font-size: 45px;
					}
				}
			}
		}
		.hero-text{
			.inner{
				width: 37%;
			}
		}
	}
}

@media (max-width: 1367px) {
	.hero {
		.hero-text {
			.inner {
				width: 40%;
			}
		}
		.curtain-container {
			.curtain-inner {
				.text {
					top: 20%;

					.main {
						font-size: 40px;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.hero {
		&.dark {
			.body {
				.table {
					width: 70%;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.hero {
		.hero-text {
			.inner {
				top: 40px;

				.main {
					font-size: 30px;
				}
			}
		}

		.curtain-container {
			.curtain-inner {
				.text {
					left: 0;
					width: 45%;

					.main {
						font-size: 40px;
					}
				}
			}
		}

		&.dark {
			.body {
				.table {
					width: 75%;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.hero {
		padding-top: 78.6%;

		.image {
			width: auto;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);

			.image-inner {
				height: 100%;

				img {
					width: auto;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
				}
			}
		}

		.body {
			.table {
				.cell {
					.hero-sub {
						width: 80vw;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.hero {
		.curtain-container {
			.curtain-inner {
				.curtain {
					width: 65%;
				}

				.text {
					top: 15%;
					width: 50%;

					.main {
						font-size: 33px;
					}
				}
			}
		}

		.hero-text {
			.inner {
				width: 52%;
			}
		}

		&.dark {
			.body {
				.table {
					width: 80%;

					.cell {
						.hero-main {
							font-size: 40px;
						}

						.hero-sub {
							font-size: 22px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 749px) {
	.hero {
		padding-top: 66%;
	}
}

@media (max-width: 425px) {
	.hero {
		min-height: calc(~'100vh - 161px');

		.hero-text {
			.inner {
				width: 100%;
				left: 0;
				top: 0;
				bottom: 0;
				padding: 40px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				&:before {
					content: none;
				}
				&:after {
					content: none;
				}
			}
		}

		.curtain-container {
			.curtain-inner {
				.curtain {
					width: 200%;
				}

				.text {
					width: 100%;
					top: 50%;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);

					.main {
						font-size: 30px;
					}
				}
			}
		}

		&.dark {
			.body {
				.table {
					width: 70%;

					.cell {
						.hero-main {
							font-size: 35px;
						}

						.hero-sub {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 375px) {
	.hero {
		.curtain-container {
			.curtain-inner {
				.text {
					.btn {
						font-size: 14px;
					}
				}
			}
		}
	}
}

@media (max-width: 320px) {
	.hero {
		.curtain-container {
			.curtain-inner {
				.text {
					padding: 38px;

					.main {
						font-size: 28px;
					}

					.sub {
						font-size: 18px;
					}
				}
			}
		}
	}
}
