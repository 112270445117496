.contact-form {
    .heading {
        text-align: left;
        width: 100%;
        padding-bottom: 30px;

        .main {
            font-size: 40px;
        }

        .sub {
            font-size: 22px;
        }
    }
}

.checkbox {
    margin-bottom: 20px;
    label {
        &:before {
            top: 2px;
            border-radius: 0;
            border: 1px solid @darkgray;
        }
        &:after {
            top: 2px;
            font-size: 12px;
        }
    }
}

.radio {
    margin-bottom: 15px;
    label {
        padding-left: 7px;
        &:before {
            width: 18px;
            height: 18px;
            border: 1px solid #d3d3d3;
            background-color: #d3d3d3;
            top: 3px;
        }
        &:after {
            width: 10px;
            height: 10px;
            left: 4px;
            top: 7px;
            background-color: #000;
        }
    }
}

@media (max-width: 425px) {
    .contact-form {
        .heading {
            .main {
                font-size: 30px;
            }

            .sub {
                font-size: 20px;
            }
        }
    }
}
