footer {
	.container {
		padding-top: 30px;
		border-top: 1px solid #5f5f5f;
	}

	h4 {
		font-size: 20px;
		font-weight: bold;
		margin-top: 0;
		margin-bottom: 20px;
	}

	ul {
		li {
			margin-bottom: 15px;
			a {
				text-decoration: underline;
			}
		}
	}

	p {
		color: #575757;
		font-size: 14px;
		margin-bottom: 0;
	}

	a {
		color: #575757;
		font-size: 14px;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}

	.support {
		color: #fff;

		.media {
			padding: 0;
		}

		.media-left {
			padding-right: 0;
			img {
				padding: 40px;
			}
		}

		.media-body {
			padding-left: 30px;
			p {
				font-family: 'Roboto Slab', serif;
				font-size: 18px;
				font-weight: 300;
			}
			h4 {
				font-family: 'Open Sans', sans-serif;
				font-size: 30px;
				font-weight: 600;
			}
		}

		.media {
			&:first-of-type {
				margin-bottom: 10px;
			}
			.media-left {
				background-color: @lightyellow;
			}
			.media-body {
				background-color: @yellow;
				p {
					color: #000;
				}
				h4 {
				}
				color: #000;
			}

			&.quote {
				.media-left {
					background-color: @darkgray;
				}
				.media-body {
					background-color: #000;
					p {
						color: #fff;
					}
					h4 {
					}
					color: #fff;
				}
			}
		}
	}

	.certificates {
		margin-top: 30px;
		ul {
			text-align: right;
			li {
				display: inline-block;
				padding: 0 10px;
				img {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 786px) {
	footer {
		.support {
			.media {
				margin-top: 15px;
				margin-bottom: 0;
			}
			.media-body {
				padding-left: 20px;
			}
		}

		.certificates {
			padding-left: 0;
			padding-right: 0;
			[class*=' col-sm'] {
				padding: 0;
			}
		}
	}
}

@media (max-width: 425px) {
	footer {
		.container {
			padding-top: 15px;
		}
		.certificates {
			.col-md-6 {
				&:first-of-type {
					text-align: center;
				}
			}
			ul {
				text-align: center;
				a {
					max-width: 25%;
				}
			}
		}
	}
}