.apply {
    padding-bottom: 70px;

    .heading {
        .main {
            font-family: 'Open Sans', sans-serif;
            font-size: 40px;
        }
    }

    .btn.bg {
        margin-top: 35px;
        float: right;
        padding: 21px 86px 21px 28px;
        border: none;
        -webkit-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);
        box-shadow: -11px 11px 24px 0px rgba(0, 0, 0, 0.21);

        i {
            border: none;
        }
    }
}
@media (max-width: 425px) {
    .apply {
        .heading {
            .main {
                font-size: 30px;
            }
        }
    }
}