.skills {
	padding-bottom: 80px;
	h4 {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.media-body {
		padding: 50px 0;

		p {
			margin-bottom: 20px;
		}

		li {
			position: relative;
			padding-left: 30px;
			padding-bottom: 10px;
			font-size: 16px;
			font-weight: 600;			

			&:before {
				content: '';
				background-image: url('../img/icons/checkmark.png');
				width: 15px;
				height: 14px;
				position: absolute;
				top: 3px;
				left: 0;
			}
		}
	}
	.skills-img {
		width: 100%;
		-webkit-box-shadow: -11px 11px 27px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: -11px 11px 27px 0px rgba(0,0,0,0.1);
		box-shadow: -11px 11px 27px 0px rgba(0,0,0,0.1);
	}
}