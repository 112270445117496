.form-control {
	border: none;
	border-bottom: 1px solid #000;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 14px 0;

	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #8e8e8e;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: #8e8e8e;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: #8e8e8e;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: #8e8e8e;
	}
	&:focus {
		border-color: transparent;
		border-bottom: 1px solid #000;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
textarea.form-control {
	border: 1px solid #000;
    padding: 14px;

	&:focus {
		border: 1px solid #000;
	}
}
.form-group {
	label {
		color: #8e8e8e;
		font-weight: 400;
	}
}

.upload-cv {
	position: relative;
	margin-top: 30px;
	height: 23px;
	margin-bottom: 0;

	input[type="file"] {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 78px;
	}

	label {
		display: inline-block;
		color: #000;
		position: absolute;
		top: -9px;
		left: 15px;
		background-color: #fff;
		width: 165px;
		font-weight: normal;
		cursor: pointer;
	}
}

input[type="file"]:focus {
	outline: none;
}
span[data-valmsg-for=File] {
    position: absolute;
    top: 28px;
    left: 14px;
}

.contact-form {
	margin-bottom: 60px;
	.btn.bg {
		margin-top: 30px;
	}
	.call {
		padding-left: 0;
		.inner {
			padding: 30px;
			background-color: @yellow;

			p {
				color: #000;
				margin-bottom: 0;
				line-height: 1.1;
				&:first-of-type {
					font-size: 18px;
				}
				&:last-of-type {
					font-size: 16px;
				}
			}
			a {
				color: #000;
				display: inline-block;
				margin-bottom: 20px;
				font-size: 30px;
				font-weight: bold;
			}
		}
		// &:nth-of-type(2) {
		// 	.inner {
		// 		background-color: #000;
		// 		p {
		// 			color: #8c8c8c;

		// 			&:first-of-type {
		// 				color: #fff;
		// 			}
		// 		}
		// 		a {
		// 			color: #fff;
		// 		}
		// 	}
		// }
	}
	.address {
		margin-top: 40px;
		h4 {
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 30px;
		}
		p {
			font-size: 20px;
			margin-bottom: 0;

			&:last-of-type {
				margin-bottom: 30px;
			}
		}
		a {
			display: block;
			font-size: 20px;
			color: #000;

			&.email {
				text-decoration: underline;
			}
		}
	}
}

@media (max-width: 425px) {
	.upload-cv {
		height: 40px;
		label {
			width: 160px;
			font-size: 18px;
		}

		input[type="file"] {   
			top: 24px;
			left: -91px;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 76px;
				width: 25px;
				height: 25px;
				background-color: #fff;
			}
		}
	}
}

@media (max-width: 1024px) {
	.contact-form {
		.call {
			.inner {
				padding: 26px;
			}
		}
	}
}
@media (max-width: 768px) {
	.contact-form {
		.call {
			.inner {
				padding: 30px;
			}
		}
		.btn.bg {
			margin-bottom: 50px;
		}
	}
}
@media (max-width: 425px) {
	.contact-form {
		.call {
			padding-bottom: 30px;
		}
		.address {
			margin-top: 0;
		}
	}
}