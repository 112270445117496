.cta {
	background: rgba(54,54,54,1);
	background: radial-gradient(ellipse at center, rgba(54,54,54,1) 0%, rgba(54,54,54,1) 17%, rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#363636', endColorstr='#000000', GradientType=1 );
	position: relative;
	margin-top: 0;

	img {
		width: 100%;
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
		-webkit-transform-origin: bottom;
		        transform-origin: bottom;
	}

	.text {
		padding: 95px 50px 0 50px;
	}

	.cta-main {
		font-family: 'Roboto Slab', serif;
		display: block;
		margin-bottom: 20px;
		font-size: 30px;
		line-height: 1;
		color: #fff;
	}

	.cta-sub {
		font-family: 'Roboto Slab', serif;
		display: block;
		margin-bottom: 40px;
		font-size: 50px;
		line-height: 1;
		color: #fff;
		font-weight: bold;
	}

	.cta-yellow {
		font-family: 'Roboto Slab', serif;
		color: @yellow;
		font-weight: bold;
		font-size: 70px;
		line-height: 1;
	}

	.row {
		margin: 0;
	}

	.phone {
		display: inline-block;
		text-align: center;
		border: 1px solid #4c4c4c;
		margin-right: 10px;
		padding: 11px 30px;
		float: left;
		span {
			font-family: 'Roboto Slab', serif;
			display: block;
			color: #fff;
			font-size: 14px;
			line-height: 1;
		}
		a {
			color: @yellow;
			font-size: 25px;
			font-weight: bold;
			line-height: 1;
		}
	}

	.contact-btn {
		display: inline-block;
	}

	.btn {
		font-size: 18px;
	}

	a {
		color: #fff;
		font-size: 40px;

		&:hover {
			text-decoration: none;
			color: #fff;
		}

		&:focus {
			text-decoration: none;
			color: #fff;
		}

		&:visited {
			text-decoration: none;
			color: #fff;
		}

		&:active {
			text-decoration: none;
			color: #fff;
		}

		i {
			padding-right: 15px;
		}
	}
	&.apply {
		padding-bottom: 0;
		.cta-sub {
			margin-bottom: 0;
		}
	}
	&.cta-contact {
		.phone {
			padding: 20px 30px;
			span {
				font-size: 18px;
			}
			a {
				font-size: 30px;
			}
		}
	}
}

@media (max-width: 1024px) {
	.cta {
		img {
			width: auto;
			-webkit-transform: scale(1.05);
			        transform: scale(1.05);
			height: 100%;
			margin-left: -60px;
		}
		.phone {
			width: 47%;
		}
	}
}

@media (max-width: 768px) {
	.cta {
		img {
			margin-left: -100px;
		}
		.text {
			padding: 100px 0 0 50px;

			.cta-sub {
				font-size: 40px;
			}
		}
	}
}

@media (max-width: 425px) {
	.cta {
		.text {
			padding: 50px 0;
			.cta-sub {
				font-size: 30px;
			}
		}
		.contact-btn {
			margin-top: 15px;
			display: block;
		}
		.btn {
			width: 100%;
			margin-top: 15px;
		}
		.phone {
			width: 100%;
		}
		&.cta-contact {
			.phone {
				margin-bottom: 15px;
			}
		}
		.cta-yellow {
			font-size: 60px;
		}
	}
}